/**
 * Homepage Code
 *
 * Each unique page (or template) has its own page script.  These
 * scripts are loaded into the template, along with the main.js
 * script, and any vendor scripts needed for the site.  The
 * individual page scripts add a 'page' object into the main
 * Otto namespace, and the main.js file then init()'s this
 * page object to execute whatever page-specific code needs
 * to be run.
 */

var Otto = Otto || {};
Otto.page = (function(my, $) {
    // Create object for storing our methods for public consumption
    // (this sets up our external API for this module)
    var exports = {},
        $slideContainer = null,
        $fixedSizeSections = null,  // sections which should be adjusted
                                    // to exact height of window
        $logo = null;

    function adjustSizes() {
        var winH = $(window).height(),
            winW = $slideContainer.width(); // Use width of container...

        // keep the height of each main 'section' equal to
        // the current window height
        $.each($fixedSizeSections, function(index, el) {
            $(el).height(winH);
        });

        // keep logo properly sized
        var logoSize = 0;
        if (winW > winH) {
            logoSize = winH * 0.7;
            $logo.height(logoSize);
            $logo.width(logoSize);
        } else {
            logoSize = winW * 0.7;
            $logo.height(logoSize);
            $logo.width(logoSize);
        }
        var offset = parseInt((winH - logoSize) / 2, 10) - 50;
        $logo.css({marginTop:offset});
    }

    function wireEvents() {
        // Set up window resize listener
        $(window).resize(function(evt){
            adjustSizes();
        });

        // Listen for scroll arrow clicks
        $('.home-photos__arrow-link').click(function(evt){
            evt.preventDefault();
            $(window).scrollTo('.home-section:eq(1)', 750);
        });
        // Listen for scroll arrow clicks
        $('.home-pizza__arrow-link').click(function(evt){
            evt.preventDefault();
            $(window).scrollTo('.home-section:eq(2)', 750);
        });
        // listen for opening of menu - only need to do this once, as
        // we only need to worry about it on desktop+
        $('#header').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                function(evt) {
                    // trigger resize event, so our carousel can adjust
                    // itself properly to the new content width
                    $(window).trigger('resize');
                });

        // set up state link click listeners
        $('.home-locations__maine').click(function(evt){
            evt.preventDefault();
            window.location.href = '/locations/maine/';
        });
        $('.home-locations__mass').click(function(evt){
            evt.preventDefault();
            window.location.href = '/locations/massachusetts/';
        });
        $('.home-locations__new-hampshire').click(function(evt){
            evt.preventDefault();
            window.location.href = '/locations/new-hampshire/';
        });
    }

    exports.init = function() {
        var slideContainerClass = '.home-slide-container',
            slideDataClass = '.home-slide-data',
            pizzaCarouselClass = '.carousel',
            pizzaCarouselItemClass = 'carousel__item',
            pizzaCarouselCaptionClass = 'banner-label banner-label--carousel';

        // Initialize background photo rotator
        if (my.hasOwnProperty("backgrounds")) {
            my.backgrounds.init(slideDataClass, slideContainerClass);
        }

        // Initialize pizza photo carousel
        if (my.hasOwnProperty("carousel")) {
            my.carousel.init(pizzaCarouselClass,
                             pizzaCarouselItemClass,
                             pizzaCarouselCaptionClass);
        }

        // Store a reference to commonly used elements
        $slideContainer = $(slideContainerClass);
        $fixedSizeSections = $('.home-photos');
        $logo = $('.home-logo');

        // Set proper heights on our elements
        adjustSizes();

        wireEvents();
    };

    return exports;

})(Otto, jQuery);